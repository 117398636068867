import { AllNavigation, AllVrboBestTimeToBook } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import { sanityBrandMap } from '../helpers/sanityBrandMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import { updateAppDownloadModulePosition } from '../helpers/updateAppDownloadModulePosition';

export class VrboBestTimeToBookService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();
  public async fetchVrboBestTimeToBookServiceTemplate(
    partner: string,
    templateName: string,
    feedName: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string
  ): Promise<any> {
    
    const variables: AllVrboBestTimeToBook.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: templateName,
          },
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const allNaviationVariables: AllNavigation.Variables = {
      where: {
        brand: {
          eq: sanityBrandMap(brand)
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const data = await this.sanityApiSource.allVrboBestTimeToBookWithNaviagationRequest(
      variables,
      allNaviationVariables,
      partner
    );

    const isValidData = checkValue(data) && checkValue(data, true) && data.length > 0;
    const [vrboBestTimeToBookData, globalNavigationData]: any = isValidData ? data : [];

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: vrboBestTimeToBookData ? [...vrboBestTimeToBookData.allVrboBestTimeToBook, ...sanityDummyData.content] : sanityDummyData.content,
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(vrboBestTimeToBookData?.data) && checkValue(vrboBestTimeToBookData?.data?.allVrboBestTimeToBook)) {
        const allVrboBestTimeToBookItem = vrboBestTimeToBookData?.data?.allVrboBestTimeToBook?.[0];
        const campaign = allVrboBestTimeToBookItem?.pos;

        const { modules, heroSetting, ...rest } =
          allVrboBestTimeToBookItem;

        let heroModule = checkValue(heroSetting)
          ? {
            module: heroSetting._type,
            dataFeed: FeedType.Sanity,
            moduleType: 'hero',
            ...heroSetting,
          }
          : null;

        const formattedModules = modules?.map((module) => ({
          module: module?.['_type'],
          ...module,
          dataFeed: FeedType.Sanity,
        }));

        const finalFormattedModules = updateAppDownloadModulePosition(formattedModules, allVrboBestTimeToBookItem?.tags?.appModule, brand)

        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          // content: formattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(globalNavigationData?.data?.allPos) &&
            checkValue(globalNavigationData.data.allPos, true) &&
            globalNavigationData?.data?.allPos.length > 0 ?
            globalNavigationData?.data?.allPos?.[0] : [],
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
