import { AllMagazineArticle, AllMagazineHome, AllNavigation } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import { sanityBrandMap } from '../helpers/sanityBrandMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import { updateAppDownloadModulePosition } from '../helpers/updateAppDownloadModulePosition';

export class MagazineTemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async fetchMagazineHomeTemplate(partner: string, templateName: string, isDummyData: boolean, pagelang: string, brand: string): Promise<any> {
    const variables: AllMagazineHome.Variables = {
      where: {
        pageUrl: {
          current: {
            eq: templateName,
          },
        },
      },
    };

    const allNaviationVariables: AllNavigation.Variables = {
      where: {
        brand:{
          eq: sanityBrandMap(brand)
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const data = await this.sanityApiSource.allMagazineHomeWithNaviagationRequest(
      variables,
      allNaviationVariables,
      partner
    );
    const isValidData = checkValue(data) && checkValue(data, true) && data.length > 0;
    const [magazineData, globalNavigationData]: any = isValidData ? data : [];

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: magazineData ? [...magazineData.data.allMagazineHome, ...sanityDummyData.content] : sanityDummyData.content,
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(magazineData?.data) && checkValue(magazineData?.data?.allMagazineHome)) {
        const allMagazineItem = magazineData?.data?.allMagazineHome?.[0];
        const campaign = allMagazineItem?.pos;
        const heroThemeTags = checkValue(allMagazineItem?.tags?.theme, true) ? allMagazineItem?.tags?.theme : [];
        const heroGeoTags = checkValue(allMagazineItem?.tags?.geo, true) ? allMagazineItem?.tags?.geo : [];
        const heroTags = [...heroThemeTags, ...heroGeoTags];

        const {
          featuredContent = [],
          dynamicContent = [],
          conversationBreaker = [],
          heroSetting,
          ...rest
        } = allMagazineItem;

        const heroModule = checkValue(heroSetting)
          ? {
              module: heroSetting._type,
              dataFeed: FeedType.Sanity,
              moduleType: 'hero',
              ...heroSetting,
            }
          : null;

        const formattedModules = [
          ...(featuredContent ?? []),
          ...(dynamicContent ?? []),
          ...(conversationBreaker ?? []),
        ]?.map((module) => ({
          module: module?.['_type'],
          ...module,
          // ...(module?.['_type'] === 'liveEventHero' ? { tags: heroTags } : {}),
          dataFeed: FeedType.Sanity,
        }));

        const finalFormattedModules = updateAppDownloadModulePosition(formattedModules , allMagazineItem?.tags?.appModule , brand)

        // const heroModule = checkValue(allMagazineItem?.heroSetting)
        //   ? { module: allMagazineItem?.heroSetting._type, dataFeed: 'sanity', ...allMagazineItem?.heroSetting }
        //   : null;
        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          // content: formattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(globalNavigationData?.data?.allPos) &&
          checkValue(globalNavigationData.data.allPos, true) && 
          globalNavigationData?.data?.allPos.length > 0 ? 
          globalNavigationData?.data?.allPos?.[0] : [],
          ignoreTextTruncate: true,
        };       
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }

  public async fetchMagazineArticleTemplate(
    partner: string,
    templateName: string,
    feedName: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string
  ): Promise<any> {
    const variables: AllMagazineArticle.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: feedName,
          },
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const allNaviationVariables: AllNavigation.Variables = {
      where: {
        brand:{
          eq: sanityBrandMap(brand)
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const data = await this.sanityApiSource.allMagazineArticleWithNaviagationRequest(
      variables,
      allNaviationVariables,
      partner
    );

    const isValidData = checkValue(data) && checkValue(data, true) && data.length > 0;
    const [magazinArticleData, globalNavigationData]: any = isValidData ? data : [];

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: magazinArticleData ? [...magazinArticleData.allMagazineArticle, ...sanityDummyData.content] : sanityDummyData.content,
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(magazinArticleData?.data) && checkValue(magazinArticleData?.data?.allMagazineArticle)) {
        const allMagazineItem = magazinArticleData?.data?.allMagazineArticle?.[0];
        const campaign = allMagazineItem?.pos;       
        //const heroThemeTags = checkValue(allMagazineItem?.tags?.theme, true) ? allMagazineItem?.tags?.theme : [];
        //const heroGeoTags = checkValue(allMagazineItem?.tags?.geo, true) ? allMagazineItem?.tags?.geo : [];
        //const heroTags = [...heroThemeTags, ...heroGeoTags];

        const { group1, group2, group3, group4, heroChoice, heroSetting, sponsorHeroSettings, ...rest } =
          allMagazineItem;       

        let heroModule = null;

        if (checkValue(heroChoice) && heroChoice === 'Sponsored Hero') {
          heroModule = checkValue(sponsorHeroSettings)
            ? {
                module: sponsorHeroSettings._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...sponsorHeroSettings,
              }
            : null;
        } else {
          heroModule = checkValue(heroSetting)
            ? {
                module: heroSetting._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...heroSetting,
              }
            : null;
        }

        const formattedModules = [...(group1 ?? []), ...(group2 ?? []), ...(group3 ?? []), ...(group4 ?? [])]?.map(
          (module) => ({
            module: module?.['_type'],
            ...module,
            // ...(module?.['_type'] === 'liveEventHero' ? { tags: heroTags } : {}),
            dataFeed: FeedType.Sanity,
          })
        );

        const finalFormattedModules = updateAppDownloadModulePosition(formattedModules , allMagazineItem?.tags?.appModule , brand)

        // const heroModule = checkValue(allMagazineItem?.heroSetting)
        //   ? { module: allMagazineItem?.heroSetting._type, dataFeed: 'sanity', ...allMagazineItem?.heroSetting }
        //   : null;
        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          // content: formattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(globalNavigationData?.data?.allPos) &&
          checkValue(globalNavigationData.data.allPos, true) && 
          globalNavigationData?.data?.allPos.length > 0 ? 
          globalNavigationData?.data?.allPos?.[0] : [],
          ignoreTextTruncate: true,
        };       
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
