import { c, xorDecrypt } from './encrypt';

export const config = {
  bexApiUrl: 'https://weaver-loom-bex.rcp.partnerexperiences.test.exp-aws.net/graphql',
  caps_api: 'https://www.expedia.com/capsapi/template-cplite/',
  ga_account: 'UA-167763302-1',
  media_store_url: '/media-studio/{page_name}',
  meso_concierge: {
    host: 'https://www.expedia.com/capsapi/template-cplite/',
    ga: 'UA-167763302-1',
    api: '/capsapi/',
    urlPattern: '/prepare/{feedName}',
    localStorageKey: 'concierge_wish_list',
  },
  meso3_sponsorbanner: {
    localStorageKey: 'meso3sponsorbanner',
  },
  price_format: {
    en_us: '${}',
    en_gb: '£{}',
    it_it: '€ {}',
    de_de: '{} €',
    fr_fr: '{} €',
    en_ca: 'CA ${}',
    fr_ca: '{} $ CA',
  },
  captureSiteKey: '6Lc2uLEbAAAAAOn038AAmm3BZsKykFzEx5nmmTJ0',
  campaignTemplates: [
    {
      campaign: 'spotlight',
      templatesPriFix: [
        'articleTemplate',
        'galleryTemplate',
        'itineraryTemplate',
        'listicleTemplate',
        'quizTemplateNew',
        'conciergeTemplateNew',
      ],
    },
    { campaign: 'cplite', templatesPriFix: ['homepage', 'ttnqOneoff'] },
    { campaign: 'concierge', templatesPriFix: ['conciergeHome'] },
    { campaign: 'mirror', templatesPriFix: ['mirrorSocialTemplate'] },
  ],
  vrboHotelSearchUrl: 'https://www.vrbo.com/serp/g',
  moduleHeadingSizes: [
    {
      type: 'default',
      fontSize: null,
      lineHeight: null,
      fontSizeMobile: null,
      lineHeightMobile: null,
    },
    {
      type: 'large',
      fontSize: 90,
      lineHeight: 1,
      fontSizeMobile: 50,
      lineHeightMobile: 1,
    },
    {
      type: 'medium',
      fontSize: 42,
      lineHeight: 56,
      fontSizeMobile: 40,
      lineHeightMobile: 48,
    },
  ],
  staticPlaceholderImage: 'https://mesocp3.azureedge.net/media/1w4lekki/static-image.png',
  googleMapsApiKey: xorDecrypt(c.gk, c.k),
  sanityEndPoint: 'https://nxpteyfv.api.sanity.io/v2023-08-01/graphql/media-studio/brand?perspective=published',
  sanityDraftEndPoint: 'https://nxpteyfv.api.sanity.io/v1/graphql/media-studio/brand',
  sanityToken: xorDecrypt(c.sk, c.k),
  themeTypes: ['Meso-Light', 'Meso-Dark', 'Brand-Light', 'Brand-Dark'],
  defaultThemeType: 'Meso-Light',
  sanityProjectId: 'nxpteyfv',
  sanityDataSet: 'media-studio',
  sanityApiVersion: '2023-08-01',
  default_EN_US_lozenge_description: `This content was paid for by [partnerName] and created by Expedia Group Media Studio. The editorial staff of Expedia Group companies had no role in this content.`,
  expAppDownload: {
    _type: 'appDownload',
    module: 'v3appDownload',
    qrCode: {
      _type: 'image',
      asset: {
        _type: 'sanity.imageAsset',
        _id: 'image-31c05ddbf2c97062eae6bc6ae6266a9f4d0bb068-499x500-png',
        assetId: '31c05ddbf2c97062eae6bc6ae6266a9f4d0bb068',
        path: 'images/nxpteyfv/media-studio/31c05ddbf2c97062eae6bc6ae6266a9f4d0bb068-499x500.png',
        url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/31c05ddbf2c97062eae6bc6ae6266a9f4d0bb068-499x500.png',
      },
      crop: null,
      hotspot: null,
    },
    headline: 'Download our app to save with member prices.',
    headlineLocKey: 'v3.appDownload.expHeading',
    subhead: 'Scan the QR code or select one of the links below.',
    subheadLocKey: 'v3.appDownload.subHeading',
    moduleColor: 'Tertiary',
    image: {
      _key: null,
      _type: 'titledMedia',
      mediaType: 'Image',
      image: {
        _type: 'image',
        asset: {
          _type: 'sanity.imageAsset',
          _id: 'image-accfb8c01ae1a6f25d707350466e43ead60d6832-993x667-jpg',
          assetId: 'accfb8c01ae1a6f25d707350466e43ead60d6832',
          path: 'images/nxpteyfv/media-studio/accfb8c01ae1a6f25d707350466e43ead60d6832-993x667.jpg',
          url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/accfb8c01ae1a6f25d707350466e43ead60d6832-993x667.jpg',
        },
        crop: null,
        hotspot: null,
      },
      altText: null,
      videoUrl: null,
      aspectRatio: '4:3',
      theme: null,
      imageGradient: false,
      title: null,
    },
    ctaGooglePlay:
      'https://www.expedia.com/app?~customer_campaign=BRAND&~customer_ad_name=BEX.BRAND.DIGITALEDITORIAL.APPBANNER&~customer_ad_set_name=EXPEDIA-US&~customer_placement=BEX.BRAND.DIGITALEDITORIAL.MWEB&%24canonical_url=https%3A%2F%2Fwww.expedia.com&_branch_match_id=1239763256088192502&_branch_referrer=H4sIAAAAAAAAA4WPTYvCMBCGf017MwXdk1CW1GaloLVUF72V2XTWBpsPkkj35G83XQ%2FqRWFO877PME%2FnvXHzJME%2FgwSMIb1Qp%2BTzws%2FOa4m24SANiKNKs5qWeXwPoG0USEwzdiD%2FGcmLZbGjK5YXu01d0BWhVZXRsmT1M%2BbQ31B2qEKZTr63DwXTA0eJyr%2B6vN6zLI6mHy26k9emOds%2B7UaVaEaj6VeYYRjIKNUKIFzLsAl2I8JBaSU49O%2Bh%2BGLxF60V6tj8WD04tOmis%2BHNK92OlVM3AQAA',
    ctaAppleStore:
      'https://www.expedia.com/app?~customer_campaign=BRAND&~customer_ad_name=BEX.BRAND.DIGITALEDITORIAL.APPBANNER&~customer_ad_set_name=EXPEDIA-US&~customer_placement=BEX.BRAND.DIGITALEDITORIAL.MWEB&%24canonical_url=https%3A%2F%2Fwww.expedia.com&_branch_match_id=1239763256088192502&_branch_referrer=H4sIAAAAAAAAA4WPTYvCMBCGf017MwXdk1CW1GaloLVUF72V2XTWBpsPkkj35G83XQ%2FqRWFO877PME%2FnvXHzJME%2FgwSMIb1Qp%2BTzws%2FOa4m24SANiKNKs5qWeXwPoG0USEwzdiD%2FGcmLZbGjK5YXu01d0BWhVZXRsmT1M%2BbQ31B2qEKZTr63DwXTA0eJyr%2B6vN6zLI6mHy26k9emOds%2B7UaVaEaj6VeYYRjIKNUKIFzLsAl2I8JBaSU49O%2Bh%2BGLxF60V6tj8WD04tOmis%2BHNK92OlVM3AQAA',
  },
  hcomAppDownload: {
    _type: 'appDownload',
    module: 'v3appDownload',
    qrCode: {
      _type: 'image',
      asset: {
        _type: 'sanity.imageAsset',
        _id: 'image-523c74e2e6b3447766e1fb6984b005f090c7a2fa-400x400-svg',
        assetId: '523c74e2e6b3447766e1fb6984b005f090c7a2fa',
        path: 'images/nxpteyfv/media-studio/523c74e2e6b3447766e1fb6984b005f090c7a2fa-400x400.svg',
        url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/523c74e2e6b3447766e1fb6984b005f090c7a2fa-400x400.svg',
      },
      crop: null,
      hotspot: null,
    },
    headline: 'Download our app to save on select hotels.',
    headlineLocKey: 'v3.appDownload.hcomHeading',
    subhead: 'Scan the QR code or select one of the links below.',
    subheadLocKey: 'v3.appDownload.subHeading',
    moduleColor: 'Tertiary',
    image: {
      _key: null,
      _type: 'titledMedia',
      mediaType: 'Image',
      image: {
        _type: 'image',
        asset: {
          _type: 'sanity.imageAsset',
          _id: 'image-cc0ab7dcec972dfb191224a494bd3d6f62e0b34b-1000x750-jpg',
          assetId: 'cc0ab7dcec972dfb191224a494bd3d6f62e0b34b',
          path: 'images/nxpteyfv/media-studio/cc0ab7dcec972dfb191224a494bd3d6f62e0b34b-1000x750.jpg',
          url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/cc0ab7dcec972dfb191224a494bd3d6f62e0b34b-1000x750.jpg',
        },
        crop: null,
        hotspot: null,
      },
      altText: null,
      videoUrl: null,
      aspectRatio: '4:3',
      theme: null,
      imageGradient: false,
      title: null,
    },
    ctaGooglePlay:
      'https://no.hotels.com/app?~customer_ad_name=BRAND.DTI&_branch_referrer=H4sIAAAAAAAAA32PTYvCMBiEf017TEH3tFCW1NbdgFYpBY8hpq8mmC%2BSlOLF3278AL24MKeZeWBGxOjCd1EIG0EFxJxDSppT8XPhY4hWg6ecacfk0ZRVh9s6fwVsoIZpePio7slb5hTjoMHE8m%2BxWaNnhfySHq%2BamvSbjuAVWu%2Ba6g0KwK0ZmD9TN%2B6VDAL8vzjebivctk2XZ7OvAcIpWkdHr0pxe5XNcTZbJk3ThJ7%2FuNXJSC9vBGfGGsmZejD3Roo%2Bc9l8mV88HMB7aY507%2B0U0sSF8Gn%2BFTXjgLlKAQAA&%24canonical_url=hotelsapp%3A%2F%2Fwww.hotels.com%2F%3F&~customer_secondary_publisher=HCOM.BRAND.DIGITALEDITORIAL.APPBANNER&~customer_campaign=BRAND&_branch_match_id=1239763256088192502&~customer_placement=HCOM.BRAND.DIGITALEDITORIAL.MWEB&locale=nb_NO&pos=HCOM_NO&siteid=300000012',
    ctaAppleStore:
      'https://no.hotels.com/app?~customer_ad_name=BRAND.DTI&_branch_referrer=H4sIAAAAAAAAA32PTYvCMBiEf017TEH3tFCW1NbdgFYpBY8hpq8mmC%2BSlOLF3278AL24MKeZeWBGxOjCd1EIG0EFxJxDSppT8XPhY4hWg6ecacfk0ZRVh9s6fwVsoIZpePio7slb5hTjoMHE8m%2BxWaNnhfySHq%2BamvSbjuAVWu%2Ba6g0KwK0ZmD9TN%2B6VDAL8vzjebivctk2XZ7OvAcIpWkdHr0pxe5XNcTZbJk3ThJ7%2FuNXJSC9vBGfGGsmZejD3Roo%2Bc9l8mV88HMB7aY507%2B0U0sSF8Gn%2BFTXjgLlKAQAA&%24canonical_url=hotelsapp%3A%2F%2Fwww.hotels.com%2F%3F&~customer_secondary_publisher=HCOM.BRAND.DIGITALEDITORIAL.APPBANNER&~customer_campaign=BRAND&_branch_match_id=1239763256088192502&~customer_placement=HCOM.BRAND.DIGITALEDITORIAL.MWEB&locale=nb_NO&pos=HCOM_NO&siteid=300000012',
  },
  vrboAppDownload: {
    module: 'v3appDownload',
    _type: 'appDownload',
    qrCode: {
      _type: 'image',
      asset: {
        _type: 'sanity.imageAsset',
        _id: 'image-fd727ec5bd6d8e402c13d756c7ec560e8710317a-500x500-png',
        assetId: 'fd727ec5bd6d8e402c13d756c7ec560e8710317a',
        path: 'images/nxpteyfv/media-studio/fd727ec5bd6d8e402c13d756c7ec560e8710317a-500x500.png',
        url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/fd727ec5bd6d8e402c13d756c7ec560e8710317a-500x500.png',
      },
      crop: null,
      hotspot: null,
    },
    headline: 'Plan, book and earn:<br/>Go further with our app.',
    headlineLocKey: 'v3.appDownload.vrboHeading',
    subhead: 'Scan the QR code or select one of the links below.',
    subheadLocKey: 'v3.appDownload.subHeading',
    moduleColor: 'Tertiary',
    image: {
      _key: null,
      _type: 'titledMedia',
      mediaType: 'Image',
      image: {
        _type: 'image',
        asset: {
          _type: 'sanity.imageAsset',
          _id: 'image-62991b2d6427d3377f8e035c9fe3c885c89015f9-1000x667-jpg',
          assetId: '62991b2d6427d3377f8e035c9fe3c885c89015f9',
          path: 'images/nxpteyfv/media-studio/62991b2d6427d3377f8e035c9fe3c885c89015f9-1000x667.jpg',
          url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/62991b2d6427d3377f8e035c9fe3c885c89015f9-1000x667.jpg',
        },
        crop: null,
        hotspot: null,
      },
      altText: null,
      videoUrl: null,
      aspectRatio: '4:3',
      theme: null,
      imageGradient: false,
      title: null,
    },
    ctaGooglePlay:"https://www.vrbo.com/app?%7Ecustomer_ad_set_name=-VRBO-US&%7Ecustomer_campaign=BRAND&%7Ecustomer_ad_name=VRBO.BRAND.DIGITALEDITORIAL.APPBANNER&%7Ecustomer_placement=VRBO.BRAND.DIGITALEDITORIAL.MWEB&%24canonical_url=https%3A%2F%2Fwww.vrbo.com%2F&emlcid=VRBO.BRAND.DIGITALEDITORIAL.MWEB&_branch_match_id=1239763256088192502&_branch_referrer=H4sIAAAAAAAAA4XPTWvCQBAG4F%2BT3NwU7akQZNPEEtAoqW3ByzLZjGZp9oPZtbn525vYQ9uLwjAwvDwwbxeC809JEtgXNZYpmyyOHvQhfzC%2BWV7k2QerkQS0wmMQBjSms%2Fc6287eXuPfWIJ2oE4mzWpe5fE%2FdzUTYdeQ5eVLuefrIi%2F327rka8Z3u4xXVVH%2Fca4HiRpNuCk3H0UWR%2FPHFv1nsE6cqU%2B7qVG04NF8Nc4wDD%2FNpNXjCc6NeyISjDVKQn8fxah7qdr7n1wIj0ikzEk0ZAePlD53NPb5BnUH%2FEJmAQAA",
    ctaAppleStore:"https://www.vrbo.com/app?%7Ecustomer_ad_set_name=-VRBO-US&%7Ecustomer_campaign=BRAND&%7Ecustomer_ad_name=VRBO.BRAND.DIGITALEDITORIAL.APPBANNER&%7Ecustomer_placement=VRBO.BRAND.DIGITALEDITORIAL.MWEB&%24canonical_url=https%3A%2F%2Fwww.vrbo.com%2F&emlcid=VRBO.BRAND.DIGITALEDITORIAL.MWEB&_branch_match_id=1239763256088192502&_branch_referrer=H4sIAAAAAAAAA4XPTWvCQBAG4F%2BT3NwU7akQZNPEEtAoqW3ByzLZjGZp9oPZtbn525vYQ9uLwjAwvDwwbxeC809JEtgXNZYpmyyOHvQhfzC%2BWV7k2QerkQS0wmMQBjSms%2Fc6287eXuPfWIJ2oE4mzWpe5fE%2FdzUTYdeQ5eVLuefrIi%2F327rka8Z3u4xXVVH%2Fca4HiRpNuCk3H0UWR%2FPHFv1nsE6cqU%2B7qVG04NF8Nc4wDD%2FNpNXjCc6NeyISjDVKQn8fxah7qdr7n1wIj0ikzEk0ZAePlD53NPb5BnUH%2FEJmAQAA",
  },
};
