import { isEmpty } from 'lodash';
import * as config from 'src/config/config';

export const updateAppDownloadModulePosition = (array, appModule = false, brand = 'expedia') => {
  // Filter out objects with type "appDownload"
  const filteredArray = array.filter((item) => item.module !== 'appDownload');

  // Find the first object with type "appDownload"
  // const firstAppObject = array.find(item => item.module === "appDownload");

  //get the predefined app module
  let module = null;
  if (brand === 'vrbo') {
    module = config.config.vrboAppDownload;
  } else if (brand === 'hotels') {
    module = config.config.hcomAppDownload;
  } else {
    module = config.config.expAppDownload;
  }

  // If an "appDownload" object exists, add it to the end of the filtered array
  if (module && !isEmpty(module)) {
    filteredArray.push(module);
  }

  return appModule === true ? filteredArray : array;
};
