import { AllBatch2Template } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';

export class Batch2TemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async fetchAllBatch2Template(
    partner: string,
    templateName: string,   
    isDummyData: boolean
  ): Promise<any> {
    const variables: AllBatch2Template.Variables = {
        where: {
          pageUrl2: {
            current: {
              eq: templateName,
            },
          },
        },
      };
  
      const data = await this.sanityApiSource.allBatch2Template(variables, partner);
  
      if (isDummyData) {
        const newDummyData = {
          ...sanityDummyData.seo,
          content: [...data.data.allBatch2Template, ...sanityDummyData.content],
          pageTheme: PageTheme.BrandLight,
        };
        return newDummyData;
      } else {
        if (checkValue(data) && checkValue(data.data) && checkValue(data.data.allBatch2Template)) {
          const allBatchItem = data?.data?.allBatch2Template?.[0];
          const campaign = allBatchItem?.pos;
          const { modules, ...rest } = allBatchItem;
  
          const showMarkerIo = allBatchItem?.showMarkerIo;
  
          const formattedModules = modules?.map((module) => ({
            module: module._type,
            ...module,
            dataFeed: FeedType.Sanity,
          }));
  
          // const heroModule = checkValue(allBatchItem?.heroSetting)
          //   ? { module: allBatchItem?.heroSetting._type, dataFeed: 'sanity', ...allBatchItem?.heroSetting }
          //   : null;
          const finalFormattedData = {
            ...rest,
            ...campaign,
            content: formattedModules,
            showMarkerIo,
            pageTheme: PageTheme.BrandLight,
            dataFeed: FeedType.Sanity,
            //pageTags: checkValue(allBatchItem?.tags?.theme, true) ? allBatchItem?.tags?.theme : [],
            pageTags:[{prefLabel:"Beach"} , {prefLabel:"Nature"}],
            ignoreTextTruncate: true,
          };
          return finalFormattedData;
        } else {
          return [];
        }
      }
    }
}