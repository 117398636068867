/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  UitkHeading,
  UitkParagraph,
  UitkText,
  UitkHeadingProps,
  UitkParagraphProps,
  UitkTextProps,
} from 'uitk-react-text';
import { checkValue, addNBSPs } from 'src/components/common/helper';
import { TextBalancer } from 'src/components/common/ui';
import { useComponentContext } from 'src/components/common/modules/component-layout/component-context';

// HEADING ----------------------------------------------------
interface HeadingProps extends UitkHeadingProps {
  children?: React.ReactNode;
  exClass?: string;
}

const Heading: React.FC<HeadingProps> = (props) => {
  const { exClass = '', children, ...rest } = props;

  return (
    <UitkHeading className={`cms-typo ${exClass}`} {...rest}>
      {children}
    </UitkHeading>
  );
};

// PARAGRAPH ----------------------------------------------------
interface ParagraphProps extends UitkParagraphProps {
  children?: React.ReactNode;
  exClass?: string;
}

const Paragraph: React.FC<ParagraphProps> = (props) => {
  const { exClass = '', children, ...rest } = props;

  return (
    <UitkParagraph className={exClass} {...rest}>
      {children}
    </UitkParagraph>
  );
};

// TEXT ----------------------------------------------------
interface TextProps extends UitkTextProps {
  children?: React.ReactNode;
  exClass?: string;
}

const Text: React.FC<TextProps> = (props) => {
  const { exClass = '', children, ...rest } = props;

  return (
    <UitkText className={exClass} {...rest}>
      {children}
    </UitkText>
  );
};

// DIV ----------------------------------------------------
interface TextProps extends UitkTextProps {
  children?: React.ReactNode;
  exClass?: string;
}

const Div: React.FC<TextProps> = (props) => {
  const { exClass = '', children, ...rest } = props;

  return (
    <UitkText className={exClass} {...rest}>
      {children}
    </UitkText>
  );
};

// HTML TEXT -------------------------------------------------
interface HTMLTextProps {
  tag: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'section' | 'span';
  children: any;
  exClass?: string;
  exId?: string;
  style?: React.CSSProperties;
  addNBSPstoMax3Chars?: boolean;
  className?: string; // classname comes from styled components
  dataCy?: string; // custom attributes
}

const HTMLText: React.FC<HTMLTextProps> = (props) => {
  let context = null;
  try {
    if (useComponentContext()) {
      context = useComponentContext();
    }
  } catch (e) {}

  const wordBalanceAllowedModules = [
    'v2IntroductionModule',
    'v2FeaturedArticleModule',
    'v2ArticlePanelModule',
    'v2QuoteModule',
    'v2IconModule',
    'v2TextModule',
    'v2PhotoQuoteModule',
    'v2SideRailModule',
  ];

  const { exClass = '', children, tag, exId = '', style, addNBSPstoMax3Chars = false, className = '', dataCy = '' } = props;
  const T = `${tag}` as keyof JSX.IntrinsicElements;
  return context && wordBalanceAllowedModules.includes(context?.componentProps?.data?.module) ? (
    <TextBalancer>
      <T
        className={`${exClass} ${checkValue(className) ? className : 'cms-typo'}`}
        {...(checkValue(style) ? { style: style } : '')}
        {...(checkValue(exId) ? { id: `${exId}` } : '')}
        dangerouslySetInnerHTML={{ __html: addNBSPs(children, addNBSPstoMax3Chars) }}
        data-cy={dataCy}
      ></T>
    </TextBalancer>
  ) : (
    <T
      className={`${exClass} ${checkValue(className) ? className : 'cms-typo'}`}
      {...(checkValue(style) ? { style: style } : '')}
      {...(checkValue(exId) ? { id: `${exId}` } : '')}
      dangerouslySetInnerHTML={{ __html: children }}
      data-cy={dataCy}
    ></T>
  );
};

interface LinkTextProps {
  text: string;
  url?: string;
  target?: '_blank' | '';
  exClass?: string;
  id?: string;
}

const LinkText: React.FC<LinkTextProps> = (props) => {
  const { text, url, exClass = '', target = '', id='' } = props;
  return checkValue(url) ? (
    <a href={url} target={target} className={exClass} id={id}>
      <Typo.HTMLText tag="p" exClass={exClass} >{text}</Typo.HTMLText>
    </a>
  ) : (
    <Typo.HTMLText tag="p" exClass={exClass}>
      {text}
    </Typo.HTMLText>
  );
};

export const Typo = {
  Heading,
  Paragraph,
  Text,
  HTMLText,
  LinkText,
};

export { HeadingProps, ParagraphProps, TextProps, LinkTextProps };
